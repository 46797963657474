<template>
  <div class="home">
    <CustomHeader></CustomHeader>
    <div class="banner-item">
      <div class="slogan">
        <p class="p1">CUSTOMER CASE</p>
        <p class="p2">客户案例</p>
        <p class="p3" style="width: 100%">艾航通过无人机自动化应用创造全新的智能模式</p>
      </div>
    </div>

    <div class="wrap-bg">
      <div class="core top">
        <h1 class="h1">客户案例</h1>
        <Title title="Customer Case"></Title>
      </div>
      <div class="card core">
        <div class="img-wrap">
          <el-carousel class="car" arrow="never">
            <el-carousel-item v-for="item in list[0]" :key="item.url" class="car-item">
              <img :src="item.url" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="card-inner">
          <div class="title">
            <p class="big">01</p>
            <div class="name">台州市某供电所</div>
          </div>
          <div class="card-text">开展无人机基于固定机巢的智能巡检技术，实现供电所线路智能巡检全覆盖，相比于传统人工运维提高30倍以上工作效率，相比于单兵无人机作业提高6倍以上作业效率。</div>
        </div>
      </div>
      <div class="card core">
        <div class="card-inner card-right">
          <div class="title">
            <p class="big">02</p>
            <div class="name">浙江省某电力施工现场</div>
          </div>
          <div class="card-text">利用大载重无人机开展物资运输工作，可实现150kg材料单次物资运输工作，节省大量人力物力并且缩短工程时间。</div>
        </div>
        <div class="img-wrap right">
          <el-carousel class="car" arrow="never" interval="4000">
            <el-carousel-item v-for="item in list[1]" :key="item.url" class="car-item">
              <img :src="item.url" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="card core">
        <div class="img-wrap">
          <el-carousel class="car" arrow="never">
            <el-carousel-item v-for="item in list[2]" :key="item.url" class="car-item">
              <img :src="item.url" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="card-inner">
          <div class="title">
            <p class="big">03</p>
            <div class="name">江苏省某镇</div>
          </div>
          <div class="card-text">采取“无人机空中巡查+人员地面寻访”方式，开展无人机日常巡检、定时巡逻工作，针对街道违章、垃圾堆放、污水排放等现场存在问题进行自动识别。并将结果及时反馈至对应街道办网格长。</div>
        </div>
      </div>
      <div class="card core">
        <div class="card-inner card-right">
          <div class="title">
            <p class="big">04</p>
            <div class="name">台州市某公安</div>
          </div>
          <div class="card-text">开展空天地一体化联动，通过单兵无人机、固定机巢、移动机巢开展快速巡检工作，可在指挥室进行远程控制、远程监管、远程喊话等工作。</div>
        </div>
        <div class="img-wrap right">
          <el-carousel class="car" arrow="never" interval="3500">
            <el-carousel-item v-for="item in list[3]" :key="item.url" class="car-item">
              <img :src="item.url" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="card core">
        <div class="img-wrap">
          <el-carousel class="car" arrow="never" interval="4000">
            <el-carousel-item v-for="item in list[4]" :key="item.url" class="car-item">
              <img :src="item.url" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="card-inner">
          <div class="title">
            <p class="big">05</p>
            <div class="name">山西省某山林</div>
          </div>
          <div class="card-text">通过固定翼无人机开展大面积常态化巡检作业主要通过指挥室下发作业任务定期开展山林火源定位、日常监测。同时配合便携式无人机进行低空巡逻，载重无人机进行物资运输响应火灾救援等工作。</div>
        </div>
      </div>
    </div>

    <Fixed class="opcity"></Fixed>
    <CustomFooter></CustomFooter>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        [
          {
            url: "https://cdn.aihangtec.com/index/image/custom/供电所机巢/g1.jpg"
          },
          {
            url: "https://cdn.aihangtec.com/index/image/custom/供电所机巢/g2.jpg"
          },
          {
            url: "https://cdn.aihangtec.com/index/image/custom/供电所机巢/g3.jpg"
          },
          {
            url: "https://cdn.aihangtec.com/index/image/custom/供电所机巢/g4.jpg"
          }
        ],
        [
          {
            url: "https://cdn.aihangtec.com/index/image/custom/重载无人机/c1.jpg"
          },
          {
            url: "https://cdn.aihangtec.com/index/image/custom/重载无人机/c2.jpg"
          },
          {
            url: "https://cdn.aihangtec.com/index/image/custom/重载无人机/c3.jpg"
          }
        ],
        [
          {
            url: "https://cdn.aihangtec.com/index/image/custom/河道巡检/h1.jpg"
          },
          {
            url: "https://cdn.aihangtec.com/index/image/custom/河道巡检/h2.jpg"
          },
          {
            url: "https://cdn.aihangtec.com/index/image/custom/河道巡检/h3.jpg"
          }
        ],
        [
          {
            url: "https://cdn.aihangtec.com/index/image/custom/公安/g3.jpg"
          },
          {
            url: "https://cdn.aihangtec.com/index/image/custom/公安/g4.jpg"
          }
        ],
        [
          {
            url: "https://cdn.aihangtec.com/index/image/custom/林业/l1.JPG"
          },
          {
            url: "https://cdn.aihangtec.com/index/image/custom/林业/l2.png"
          },
          {
            url: "https://cdn.aihangtec.com/index/image/custom/林业/l3.png"
          }
        ]
      ]
    }
  }
}
</script>
<style scoped lang="scss">
p,
ul {
  margin: 0;
}
::v-deep .el-carousel__button {
  @media screen and (max-width: 540px) {
    width: 18px !important;
  }
}
::v-deep .el-carousel__indicator--horizontal {
  @media screen and (max-width: 540px) {
    padding: 0px 0px !important;
  }
}
.opcity {
  @media screen and (max-width: 540px) {
    display: none;
  }
  @media screen and (min-width: 540px) {
    display: block;
  }
}
.unopcity {
  @media screen and (max-width: 540px) {
    display: block;
  }
  @media screen and (min-width: 540px) {
    display: none;
  }
}
.top {
  @media screen and (max-width: 540px) {
    padding: 20px 0 10px;
  }
  @media screen and (min-width: 540px) {
    padding: 80px 0;
  }
}

.banner-item {
  background-size: 100% 100%;
}
.card {
  display: flex;
  @media screen and (max-width: 540px) {
    padding: 0 20px;
    margin-bottom: 20px;
  }
  @media screen and (min-width: 540px) {
    margin-bottom: 80px;
  }
  .img-wrap {
    position: relative;
    @media screen and (max-width: 540px) {
      top: 18px;
      right: -10px;
    }
    @media screen and (min-width: 540px) {
      width: 410px;
      height: 370px;
      top: 55px;
    }
    .car {
      @media screen and (max-width: 540px) {
        width: 45vw;
        height: 105px;
      }
      @media screen and (min-width: 540px) {
        width: 500px;
        height: 370px;
      }
      .car-item {
        @media screen and (max-width: 540px) {
          width: 45vw;
          height: 105px;
        }
        @media screen and (min-width: 540px) {
          width: 500px;
          height: 370px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .card-inner {
    @media screen and (max-width: 540px) {
      width: 55vw !important;
      height: 140px;
      padding: 8px 6px 5px 25px;
    }
    @media screen and (min-width: 540px) {
      width: 790px !important;
      height: 480px;
      padding: 88px 36px 55px 122px;
    }
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.01);
    border-radius: 10px;
    .title {
      .big {
        @media screen and (max-width: 540px) {
          font-size: 14px;
          line-height: 15px;
          margin: 10px 0;
        }
        @media screen and (min-width: 540px) {
          font-size: 50px;
          height: 50px;
          line-height: 75px;
          margin-bottom: 12px;
        }
        font-weight: 400;
        color: #1a66fc;
        opacity: 20%;
      }
      .name {
        @media screen and (max-width: 540px) {
          font-size: 10px;
          line-height: 10px;
        }
        @media screen and (min-width: 540px) {
          height: 30px;
          font-size: 30px;
          line-height: 45px;
        }
        font-weight: bold;
        color: #000000;
        letter-spacing: 3px;
      }
    }
    .card-text {
      @media screen and (max-width: 540px) {
        font-size: 8px;
        line-height: 10px;
        margin-top: 5px;
      }
      @media screen and (min-width: 540px) {
        font-size: 18px;
        line-height: 32px;
        margin-top: 45px;
      }
      font-weight: 400;
      color: #000000;
      letter-spacing: 2px;
    }
  }
  .right {
    @media screen and (max-width: 540px) {
      left: -5px;
    }
    @media screen and (min-width: 540px) {
      left: -86px;
    }
  }
}
.wrap-bg {
  background: #f7f7f9;
  @media screen and (max-width: 540px) {
    padding-bottom: 20px;
  }
  @media screen and (min-width: 540px) {
    padding-bottom: 80px;
  }
}
.card-right {
  @media screen and (max-width: 540px) {
    padding: 8px 12px 5px 12px !important;
  }
  @media screen and (min-width: 540px) {
    padding: 88px 122px 55px 36px !important;
  }
}
::v-deep .el-carousel__container {
  height: 105px;
}
</style>
